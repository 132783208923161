import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  return (
    <div style={{position: 'relative', backgroundColor: appearance.backgroundColor}}>
      <div className={css.sectionDividerContainer} >
        <div className={css.sectionDividerBlock} ></div>
        <svg className={css.sectionDividerClip} >
          <clipPath id="section-divider-clippath" clipPathUnits="objectBoundingBox">
            <path className={css.sectionDividerClippathHalfmoon} d="M-1, 0.959 L-1, 0.959 l0,0 c0,0 0.25,0.041 0.5,0.041 s0.5,-0.041 0.5,-0.041 l0,0l0,0 c0,0 0.25,0.041 0.5,0.041 s0.5,-0.041 0.5,-0.041 l0,0l0,0 c0,0 0.25,0.041 0.5,0.041 s0.5,-0.041 0.5,-0.041 l0,0 L1, -1 L0, -1 z"></path>
            <path className={css.sectionDividerClippathHalfmoonTwo} d="M-1, 0.8109999999999999 L-1, 0.8109999999999999 l0,0 c0,0 0.25,0.189 0.5,0.189 s0.5,-0.189 0.5,-0.189 l0,0l0,0 c0,0 0.25,0.189 0.5,0.189 s0.5,-0.189 0.5,-0.189 l0,0l0,0 c0,0 0.25,0.189 0.5,0.189 s0.5,-0.189 0.5,-0.189 l0,0 L1, -1 L0, -1 z"></path>
          </clipPath>
        </svg>
      </div>

    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
    </SectionContainer>

    
    {/* <div id={`clippath-${sectionId}`} style={{height: '6vw', position: 'absolute', bottom: 0, left: 0, width: '100%'}}></div> */}
    </div>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
